<template>
    <div class="app">
        <a-config-provider
            :locale="zhCN"
            :auto-insert-space-in-button="false"
            componentSize="middle"
            :theme="{
                token: {
                    colorPrimary: '#0960bd',
                },
            }"
        >
            <router-view></router-view>
        </a-config-provider>
    </div>
</template>

<script lang="ts" setup>
import { ConfigProvider as AConfigProvider } from "ant-design-vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
</script>

<style lang="less">
#app {
    height: 100%;
}
.app {
    height: 100%;
}
#webpack-dev-server-client-overlay {
  display: none !important;
}
</style>
