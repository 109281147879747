import http from "@/service";

const api = {
    getReport: '/report/get-dsp-api-report',
    getReportUrl: '/quick-bi/get'
}

export const getReport = (params: any) => {
    return http.post(api.getReport, params);
};

export const getReportUrl = (params: any) => {
    return http.post(api.getReportUrl, params);
};