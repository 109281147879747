import { createPinia, defineStore } from "pinia";
import cache from "@/utils/cache";
import { getReportUrl } from '@/api/dsp-report'

const pinia = createPinia();

interface RootStoreState {
  token: string;
  account: {
    username: string;
    role: string;
    userId: number;
  };
}

const initAccount = () => {
  return {
    username: "",
    role: "",
    userId: 0
  };
};

export const useStore = defineStore("root", {
  state(): RootStoreState {
    return {
      token: "",
      account: {
        username: "",
        role: "",
        userId: 0
      }
    };
  },
  actions: {
    setToken(token: string) {
      this.token = token;
    },
    cleanToken() {
      this.token = "";
    },
    resetAccount() {
      this.account = initAccount();
    }
  },
  getters: {
    getToken(): string {
      return this.token || cache.getCache("Adx-Token");
    }
  }
});

export const getReport = (pageId: string) => {
  return new Promise((resolve, reject) => {
    getReportUrl({pageId}).then((res) => {
      if (res.code == 0) {
        resolve(res);
      } else {
        reject();
      }
    }).catch(() => {
      reject();
    });
  })
}

export default pinia;
