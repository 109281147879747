import { createRouter, createWebHashHistory } from "vue-router";
import { Modal } from "ant-design-vue";
import { useStore } from "@/store";
import routes from "./routes";
import cache from "@/utils/cache";

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to) => {
  const store = useStore();
  const cacheToken = cache.getCache("Adx-Token");
  if (cacheToken) {
    store.setToken(cacheToken);
  }
  const freeRoutes = ["/login","/cas-callback"];
  if (freeRoutes.includes(to.path)) {
    return;
  }
  if (!store.token) {
    return "/login";
  }
});

// 错误处理函数，防止每次打包后 客户端路由切换会报错 自动更新代码
router.onError((error, to) => {
  console.log("chunck load error", error);
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    Modal.confirm({
      title: "因版本更新, 页面需要重新载入",
      onOk() {
        window.location.reload();
        router.replace(to.fullPath);
      },
      onCancel() {
        window.location.reload();
        router.replace(to.fullPath);
      }
    });
  }
});

export default router;
